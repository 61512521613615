<template>
  <header :class="containedWidth ? 'mx-auto max-w-7xl' : ''" class="px-4 lg:px-10">
    <nav class="flex items-center justify-between py-4" aria-label="Global">
      <div class="flex shrink-0 items-center gap-x-12">
        <RouterLink
          :to="CROSSGRID_PAGE_URLS.general.home"
          class="-m-1.5 flex items-center space-x-3 p-1.5">
          <img
            class="h-7 w-auto"
            :src="
              !darkMode
                ? `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-logo.svg`
                : `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-logo-dark.svg`
            "
            alt="Crossgrid" />
        </RouterLink>
      </div>
      <div v-if="!isDashboard && isHome" class="flex gap-10">
        <div v-for="item in links" :key="item.name" class="hidden grow-0 md:flex lg:gap-x-12">
          <a
            :href="item.href"
            class="text-md font-normal leading-6 text-fg-tertiary dark:text-fg-tertiary-dark"
            >{{ item.name }}</a
          >
        </div>
      </div>

      <div v-if="!userSession?.is_authenticated" class="hidden items-center gap-3 md:flex">
        <a
          :href="CROSSGRID_PAGE_URLS.allauth.login"
          class="text-md px-4 py-2.5 font-semibold leading-6 text-fg-tertiary dark:text-fg-tertiary-dark"
          >Log in</a
        >
        <RouterLink :to="CROSSGRID_PAGE_URLS.general.signup">
          <button
            type="button"
            class="text-md rounded-md bg-button-primary-bg px-4 py-2.5 font-semibold text-button-primary-fg shadow-sm hover:bg-button-primary-bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-button-primary-border">
            Sign up
          </button>
        </RouterLink>
      </div>

      <div v-else class="hidden items-center gap-3 md:flex">
        <RouterLink
          v-if="!isDashboard && userSession?.pro_listing && userSession?.profile_setup_complete"
          :to="CROSSGRID_PAGE_URLS.pro.dashboard.home">
          <button
            type="button"
            class="text-md hidden rounded-md bg-button-primary-bg px-4 py-2.5 font-semibold text-button-primary-fg shadow-sm hover:bg-button-primary-bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-button-primary-border lg:block">
            Dashboard →
          </button>
        </RouterLink>

        <div class="relative flex-shrink-0">
          <Popover class="relative">
            <PopoverButton
              class="rounded-full focus:outline-none focus:ring-2 focus:ring-border-primary/25 dark:focus:ring-border-brand-dark/25">
              <img
                v-if="userSession?.photo_url"
                :src="userSession?.photo_url"
                alt="Profile picture"
                class="h-10 w-10 rounded-full border border-border-primary dark:border-border-primary-dark"
                loading="lazy" />
              <span
                v-else
                class="inline-flex h-10 w-10 items-center justify-center rounded-full border border-border-primary bg-bg-tertiary hover:bg-bg-tertiary-hover dark:border-border-primary-dark dark:bg-bg-tertiary-dark dark:hover:bg-bg-tertiary-dark-hover">
                <span
                  class="font-medium leading-none text-fg-quaternary dark:text-fg-quaternary-dark"
                  >{{ userSession?.initials }}</span
                >
              </span>
            </PopoverButton>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel
                class="absolute right-0 z-10 mt-2 flex w-screen max-w-max origin-top-right translate-y-1 py-1">
                <div
                  class="w-screen max-w-sm flex-auto overflow-hidden rounded-3xl bg-bg-primary text-sm leading-6 shadow-xl ring-1 ring-border-secondary dark:bg-bg-primary-dark dark:ring-border-secondary-dark">
                  <div v-if="userSession?.pro_listing" class="p-4">
                    <div
                      v-for="item in menuItems"
                      :key="item.name"
                      class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-bg-primary-hover dark:hover:bg-bg-primary-dark-hover">
                      <div
                        class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-bg-secondary group-hover:bg-bg-primary dark:bg-bg-secondary-dark dark:group-hover:bg-bg-primary-dark">
                        <component
                          :is="item.icon"
                          class="h-6 w-6 text-fg-quaternary group-hover:text-fg-brand-primary dark:text-fg-quaternary-dark dark:group-hover:text-fg-brand-primary-dark"
                          aria-hidden="true" />
                      </div>
                      <div>
                        <a
                          :href="`/v${item.href}`"
                          class="font-semibold text-text-primary dark:text-text-primary-dark">
                          {{ item.name }}
                          <span class="absolute inset-0"></span>
                        </a>
                        <p class="mt-1 text-text-secondary dark:text-text-secondary-dark">
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-2 divide-x divide-border-secondary bg-bg-secondary dark:divide-border-secondary-dark dark:bg-bg-secondary-dark">
                    <div class="flex items-center justify-center gap-x-2.5 p-3">
                      <CrossgridSwitch
                        v-model="darkMode"
                        :dark-mode-switch="true"
                        label="Darkmode" />
                    </div>
                    <a
                      data-e2e="logout-button"
                      class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-text-primary hover:bg-bg-secondary-hover dark:text-text-primary-dark dark:hover:bg-bg-secondary-dark-hover"
                      @click="logout">
                      <ArrowLeftEndOnRectangleIcon
                        class="h-5 w-5 flex-none text-fg-quaternary dark:text-fg-quaternary-dark"
                        aria-hidden="true" />
                      Log out
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
      </div>

      <div class="flex md:hidden">
        <button
          type="button"
          aria-expanded="false"
          aria-haspopup="true"
          @click="mobileMenuOpen = true">
          <Bars3Icon class="h-6 w-6 text-fg-primary dark:text-fg-primary-dark" aria-hidden="true" />
        </button>
      </div>
    </nav>

    <Dialog as="div" class="lg:hidden" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
      <div class="fixed inset-0 z-10"></div>
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-bg-primary px-6 py-6 dark:bg-bg-primary-dark">
        <div class="flex items-center justify-between">
          <a :href="CROSSGRID_PAGE_URLS.general.home" class="-m-1.5 flex items-center p-1.5">
            <img
              class="h-8 w-auto"
              :src="
                !darkMode
                  ? `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-logo.svg`
                  : `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-logo-dark.svg`
              "
              alt="Crossgrid" />
          </a>
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-fg-primary dark:text-fg-primary-dark"
            @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-border-secondary dark:divide-border-secondary-dark">
            <div v-if="userSession?.pro_listing" class="space-y-2 py-6">
              <button
                v-for="item in menuItems"
                :key="item.name"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-bg-primary-hover dark:text-text-primary-dark dark:hover:bg-bg-primary-dark-hover"
                @click="selectMobileMenuOption(item.href)">
                {{ item.name }}
              </button>
            </div>
            <div class="space-y-2 py-6">
              <a
                v-for="item in links"
                :key="item.name"
                :href="item.href"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-bg-primary-hover dark:text-text-tertiary-dark dark:hover:bg-bg-tertiary-dark-hover"
                @click="selectMobileMenuOption(item.href)">
                {{ item.name }}
              </a>
            </div>
            <div v-if="userSession?.is_authenticated" class="flex justify-evenly space-y-2 py-6">
              <a
                class="-mx-3 flex items-center gap-x-2.5 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-bg-primary-hover dark:text-text-primary-dark dark:hover:bg-bg-primary-dark-hover"
                @click="logout">
                <ArrowLeftEndOnRectangleIcon
                  class="h-5 w-5 flex-none text-fg-quaternary dark:text-fg-quaternary-dark"
                  aria-hidden="true" />
                Log out
              </a>

              <div class="-mx-3 flex items-center gap-x-2.5 px-3 py-2">
                <CrossgridSwitch v-model="darkMode" :dark-mode-switch="true" label="Darkmode" />
              </div>
            </div>
            <div v-else class="space-y-2 py-6">
              <a
                class="-mx-3 flex items-center gap-x-2.5 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-bg-primary-hover dark:text-text-primary-dark dark:hover:bg-bg-primary-dark-hover"
                :href="CROSSGRID_PAGE_URLS.allauth.login">
                Log in
              </a>
              <RouterLink
                class="-mx-3 flex items-center gap-x-2.5 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-bg-primary-hover dark:text-text-primary-dark dark:hover:bg-bg-primary-dark-hover"
                :to="CROSSGRID_PAGE_URLS.general.signup">
                Sign up
              </RouterLink>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script lang="ts" setup>
import {onMounted, ref, Ref, watch} from 'vue';
import {Dialog, DialogPanel, Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {ArrowLeftEndOnRectangleIcon} from '@heroicons/vue/20/solid';
import {ChartPieIcon, XMarkIcon} from '@heroicons/vue/24/outline';
import {Bars3Icon} from '@heroicons/vue/24/solid';
import {RouterLink, useRouter} from 'vue-router';

import {CROSSGRID_IMAGE_HOSTING_BASE_URL, CROSSGRID_PAGE_URLS} from '@/constants/common';
import {MinimalUser} from '@/types/user';
import {getSession} from '@/utils/shared';

import CrossgridSwitch from '@/components/atoms/CrossgridSwitch.vue';

const router = useRouter();

const userSession: Ref<MinimalUser | null> = ref(null);

const isDashboard = router.currentRoute.value.path.includes('dashboard');
type Props = {
  containedWidth?: boolean;
};
const isHome = router.currentRoute.value.path === '/';

withDefaults(defineProps<Props>(), {
  customUser: undefined,
  containedWidth: false,
});

const links = [
  {
    name: 'About',
    href: '#about',
  },
  {
    name: 'Pricing',
    href: '#pricing',
  },
  {
    name: 'Support',
    href: 'https://crossgridinc.zendesk.com/hc/en-us',
  },
];

const mobileMenuOpen = ref(false);

const menuItems = [
  {
    name: 'Dashboard',
    description: 'Manage profile and availability',
    href: CROSSGRID_PAGE_URLS.pro.dashboard.home,
    icon: ChartPieIcon,
    pro: true,
  },
];

const darkMode = ref(localStorage.theme === 'dark');

const logout = () => {
  Object.keys(localStorage).forEach(key => {
    if (key !== 'theme') {
      localStorage.removeItem(key);
    }
  });
  return (window.location.href = CROSSGRID_PAGE_URLS.allauth.logout);
};

const selectMobileMenuOption = (link: string) => {
  router.push(link);
  mobileMenuOpen.value = false;
};

watch(darkMode, darkModeEnabled => {
  darkModeEnabled ? localStorage.setItem('theme', 'dark') : localStorage.setItem('theme', 'light');
  location.reload();
});

onMounted(() => {
  getSession().then(session => (userSession.value = session));
});
</script>
