<template>
  <div class="relative">
    <component
      :is="htmlTag"
      :id="id"
      :type="type"
      :name="id"
      :placeholder="placeholder"
      :value="modelValue"
      :autocomplete="autocomplete"
      :disabled="disabled"
      v-bind="{...properties, ...$attrs}"
      :class="[
        direction === 'right' ? 'rounded-r-none' : direction === 'left' ? 'rounded-l-none' : '',
        'block w-full rounded-md border-0 bg-bg-primary py-1.5 text-text-primary shadow-sm ring-1 ring-inset placeholder:text-text-placeholder focus:ring-2 focus:ring-inset disabled:bg-bg-disabled disabled:text-text-disabled dark:bg-bg-primary-dark dark:text-text-primary-dark dark:placeholder:text-text-placeholder dark:disabled:bg-bg-disabled-dark dark:disabled:text-text-disabled-dark sm:text-sm sm:leading-6',
        errorMessages
          ? 'ring-border-error focus:ring-border-error dark:ring-border-error-dark'
          : 'ring-border-primary focus:ring-button-primary-border dark:ring-border-primary-dark',
        icon ? 'ps-9' : '',
      ]"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)" />
    <component
      :is="icon"
      v-if="icon"
      class="pointer-events-none absolute inset-y-0 start-0 m-auto flex h-7 w-7 items-center ps-3 dark:text-text-primary-dark" />
    <div
      v-if="errorMessages"
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <ExclamationCircleIcon class="h-5 w-5 text-fg-error-secondary" aria-hidden="true" />
    </div>
  </div>
  <p
    v-for="(message, index) in errorMessages"
    :id="`${id}_error`"
    :key="index"
    class="mt-1.5 text-xs text-text-error-primary dark:text-text-error-primary-dark">
    {{ message }}
  </p>
  <p
    v-if="helpText && !errorMessages"
    :id="`${id}_description`"
    class="mt-1.5 text-xs text-text-tertiary dark:text-text-tertiary-dark">
    {{ helpText }}
  </p>
</template>

<script setup lang="ts">
import {computed, FunctionalComponent, toRefs} from 'vue';
import {ExclamationCircleIcon} from '@heroicons/vue/24/outline';

import {APIStandardError} from '@/types/network';

type Props = {
  type?: 'text' | 'email' | 'multiline' | 'number' | 'hidden' | 'password' | 'search';
  placeholder?: string;
  id?: string;
  modelValue?: string;
  helpText?: string;
  autocomplete?: string;
  rows?: string;
  errors?: APIStandardError;
  disabled?: boolean;
  icon?: FunctionalComponent;
  direction?: 'default' | 'left' | 'right';
};

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  placeholder: 'Your placeholder',
  rows: '3',
  id: undefined,
  modelValue: undefined,
  helpText: undefined,
  autocomplete: undefined,
  errors: undefined,
  disabled: false,
  icon: undefined,
  value: undefined,
  direction: 'default',
});

defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const {type, rows} = toRefs(props);

const isMultiline = computed(() => {
  return type?.value === 'multiline';
});

const htmlTag = computed(() => {
  return isMultiline.value ? 'textarea' : 'input';
});

const errorMessages = computed(() => {
  if (props.errors) {
    let allErrors: string[] = [];
    const fieldErrors = props.errors?.validation_error
      ?.filter(error => error.id === props.id)
      .map(error => error.msg);
    if (fieldErrors) allErrors = allErrors.concat(fieldErrors);
    return allErrors.length < 1 ? undefined : allErrors;
  }
  return undefined;
});

const properties = computed(() => {
  return (isMultiline.value && {rows: rows.value}) || {};
});
</script>
