<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        description="You will be matched with businesses who can provide the services you select."
        :signup-flow="!userSession?.is_authenticated"
        :action-button-disabled="actionButtonDisabled"
        :errors="errors"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #title>Select any services related<br />to your needs</template>
        <template #main>
          <div class="col-span-full">
            <div class="mb-2">
              <button
                type="button"
                :data-e2e="'checkbox-toggle'"
                class="text-md rounded-md py-2.5 font-semibold text-button-primary-bg hover:text-button-primary-bg-hover"
                @click="toggleCheckBoxes()">
                {{ checkedList.length > 1 ? 'Deselect All' : 'Select All' }}
              </button>
            </div>
            <fieldset
              class="border-b border-t border-border-secondary dark:border-border-secondary-dark">
              <legend class="sr-only">Services</legend>
              <div class="divide-y divide-border-secondary dark:divide-border-secondary-dark">
                <div
                  v-for="(service, index) in proListingServicesOffered"
                  :key="index"
                  class="flex items-center py-2">
                  <div class="flex h-6 items-center">
                    <input
                      :id="service.name"
                      v-model="checkedList"
                      :tabindex="0"
                      :value="service.slug"
                      :data-e2e="`pro-service-related-input-${index}`"
                      aria-describedby="pro-services-description"
                      name="pro_services"
                      type="checkbox"
                      class="h-6 w-6 cursor-pointer rounded border-border-primary bg-white text-bg-brand-solid focus:ring-bg-brand-solid dark:border-border-primary-dark dark:bg-bg-primary-dark dark:text-bg-brand-solid-dark dark:focus:ring-bg-brand-solid-dark"
                      @keydown.enter.prevent="toggleCheckBox(service.slug)" />
                  </div>
                  <div class="text-md ml-3 leading-6">
                    <label
                      for="comments"
                      class="font-medium text-text-secondary dark:text-text-secondary-dark"
                      >{{ service.name }}</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, ref, Ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {ProService} from '@/types/pro-services';
import {MinimalUser} from '@/types/user';
import {UserSignupSavedProgress} from '@/types/user-signup';
import {useHttp} from '@/utils/composables';
import {getSession} from '@/utils/shared';
import {getUserSignupSavedProgress} from '@/utils/user-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const proListingServicesOffered: Ref<ProService[]> = ref([]);
const checkedList: Ref<string[]> = ref(['']);
const errors: Ref<APIStandardError | undefined> = ref(undefined);

const router = useRouter();
const http = useHttp();

const userSession: Ref<MinimalUser | null> = ref(null);

const handleNavigation = (action: string) => {
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.user.signup.step_one);
  if (checkedList.value.length > 0) {
    const progress = getUserSignupSavedProgress();
    // alternative route if user is logged in
    if (progress && userSession.value?.is_authenticated) {
      progress.user_two = checkedList.value;
      localStorage.setItem('user_signup_progress', JSON.stringify(progress));
      return router.push(CROSSGRID_PAGE_URLS.user.job_search.description);
    }
    // if not signed in, move to step 3
    if (progress) {
      progress.user_two = checkedList.value;
      localStorage.setItem('user_signup_progress', JSON.stringify(progress));
      return router.push(CROSSGRID_PAGE_URLS.user.signup.step_three);
    }
  }
};

const toggleCheckBoxes = () => {
  return checkedList.value.length > 1
    ? (checkedList.value = [])
    : (checkedList.value = proListingServicesOffered.value.map(service => service.slug));
};

const toggleCheckBox = (slug: string) => {
  if (checkedList.value.includes(slug)) {
    checkedList.value = checkedList.value.filter(item => item !== slug);
  } else {
    checkedList.value = [...checkedList.value, slug];
  }
};

const actionButtonDisabled = computed(() => {
  return checkedList.value.length < 1;
});

const searchProServiceRelatedServices = (
  progress: UserSignupSavedProgress | null,
  slug: string
) => {
  http
    .get(`/pro/pro_service/${slug}/related_services/`)
    .then(response => {
      checkedList.value = progress?.user_two ?? response.data.pre_checked_list;
      proListingServicesOffered.value = response.data.items;
    })
    .catch(error => {
      if (error.response?.status == 404) return (window.location.href = '/404/');
      errors.value = error.response?.data;
    });
};

onMounted(() => {
  const progress = getUserSignupSavedProgress();
  searchProServiceRelatedServices(progress, useRoute().params.slug as string);
});

onBeforeMount(() => {
  getSession().then(session => (userSession.value = session));

  // Make sure that step 1 is done, otherwise redirect user back
  const progress = getUserSignupSavedProgress();
  if (!progress || !progress.user_one) return router.push(CROSSGRID_PAGE_URLS.user.signup.step_one);
});
</script>
