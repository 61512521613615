export const CROSSGRID_SUPPORT = {
  phone: '+1 (516) 518-3353',
  email: 'success@crossgrid.com',
};

export const CROSSGRID_API_BASE_URL = '/api/v1';

export const CROSSGRID_PAGE_URLS = {
  pro: {
    signup: {
      step_one: '/pro/signup/1/',
      step_two: '/pro/signup/2/',
      step_three: '/pro/signup/3/',
      step_four: '/pro/signup/4/',
      step_five: '/pro/signup/5/',
      success: '/pro/signup/success/',
    },
    profile: {
      setup: {
        one: '/pro/profile/setup/1/',
        two: '/pro/profile/setup/2/',
      },
    },
    dashboard: {
      home: '/pro/dashboard/',
      jobs: '/pro/dashboard/jobs/',
      messages: '/pro/dashboard/messages/',
      availability: '/pro/dashboard/availability/',
      profile: '/pro/dashboard/profile/',
    },
  },
  user: {
    signup: {
      step_one: '/user/signup/1/',
      step_two: '/user/signup/2/',
      step_three: '/user/signup/3/',
      step_four: '/user/signup/4/',
      success: '/user/signup/success/',
    },
    job_search: {
      description: '/user/job_search/description/',
      success: '/user/job_search/success/',
    },
  },
  allauth: {
    signup: '/auth/signup/',
    login: '/auth/login/',
    logout: '/auth/logout/',
  },
  general: {
    home: '/',
    signup: '/signup/',
  },
  external: {
    privacy_policy:
      'https://app.termly.io/policy-viewer/policy.html?policyUUID=cf629d86-50a2-4c20-98d0-d742156d8f8d',
    terms_of_service:
      'https://app.termly.io/policy-viewer/policy.html?policyUUID=9ae28891-76c1-462e-b161-29717ccf7d69',
    cookie_policy:
      'https://app.termly.io/policy-viewer/policy.html?policyUUID=ba28d6ee-a1b4-4de0-be25-2db644fcb479',
  },
};

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const DEV = import.meta.env.DEV;

export const CROSSGRID_IMAGE_HOSTING_BASE_URL = !DEV
  ? `https://${ENVIRONMENT}.crossgrid.cloud/static/images`
  : `http://localhost:5173/static/assets/images`;
